import { SelectableList } from "components";
import { ViewOptions } from "components/views";
import { useMenuHideWindow, useScrollHandler } from "hooks";
import HackerNewsCommentsView from "../HackerNewsCommentsView";

const HackerNewsPostView = ({ post, index }) => {
  useMenuHideWindow(ViewOptions.hackernews_index.id);

  const options = [
    {
      type: "Action",
      label: `${post.title}`,
      viewId: ViewOptions.hackernews_post.id,
      breakLine: true,
      onSelect: () => {},
    },
    {
      type: "Link",
      label: `${post.score} point(s) by ${post.by}`,
      viewId: ViewOptions.hackernews_post.id,
      url: `https://news.ycombinator.com/user?id=${post.by}`,
    },
    {
      type: "View",
      label: `${post.descendants} comments - ${post.kids.length} top level comments`,
      viewId: ViewOptions.hackernews_post.id,
      component: () => <HackerNewsCommentsView topLevelComments={post.kids} />,
    },
    {
      type: "Link",
      label: `Open URL`,
      viewId: ViewOptions.hackernews_post.id,
      url: post.url || "https://www.samedayskunkworks.com",
    },
    {
      type: "Link",
      label: `Open in Hacker News`,
      viewId: ViewOptions.hackernews_post.id,
      url: `https://news.ycombinator.com/item?id=${post.id}`,
    },
  ];

  const [scrollIndex] = useScrollHandler(
    ViewOptions.hackernews_index.id,
    options
  );

  return <SelectableList options={options} activeIndex={scrollIndex} />;
};

export default HackerNewsPostView;
