import { SelectableList } from "components";
import { InstaSearchView, ViewOptions } from "components/views";
import { useMenuHideWindow, useScrollHandler } from "hooks";

const InstagramView = () => {
  useMenuHideWindow(ViewOptions.instagram.id);

  const options = [
    {
      type: "View",
      label: "Search for User",
      viewId: ViewOptions.insta_search.id,
      component: () => <InstaSearchView />,
    },
  ];

  const [scrollIndex] = useScrollHandler(ViewOptions.instagram.id, options);

  return (
    <SelectableList
      loading={false}
      options={options}
      activeIndex={scrollIndex}
    />
  );
};

export default InstagramView;
