import axios from "axios";
import { SelectableList } from "components";
import { ViewOptions } from "components/views";
import { useMenuHideWindow, useScrollHandler } from "hooks";
import { useEffect, useState } from "react";

const HackerNewsCommentsView = ({ topLevelComments }) => {
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  useMenuHideWindow(ViewOptions.hackernews_post.id);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (topLevelComments.length >= 1) {
      const test = [];
      for (var i = 0; i < topLevelComments.length; i++) {
        await axios
          .get(
            "https://hacker-news.firebaseio.com/v0/item/" +
              topLevelComments[i] +
              ".json"
          )
          // eslint-disable-next-line no-loop-func
          .then((resp) => {
            if (!resp.data.dead) {
              test.push({
                type: "Link",
                label: `${resp.data.text}`,
                viewId: ViewOptions.hackernews_comments.id,
                breakLine: true,
                url: `https://news.ycombinator.com/item?id=${resp.data.id}`,
              });
            }
          });
      }
      setLoading(false);
      setOptions(test);
    }
  }, []);

  const [scrollIndex] = useScrollHandler(
    ViewOptions.hackernews_post.id,
    options
  );

  return (
    <SelectableList
      loading={loading}
      options={options}
      activeIndex={scrollIndex}
    />
  );
};

export default HackerNewsCommentsView;
