import { useCallback, useEffect, useRef, useState } from "react";

import LoadingScreen from "components/LoadingScreen";
import { useEventListener, useWindowContext } from "hooks";
import styled from "styled-components";
import { IpodEvent } from "utils/events";
import AlbumCover from "./AlbumCover";

export type Point = {
  x: number;
  y: number;
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const AlbumsContainer = styled.div<any>`
  opacity: ${(props) => (props.loading ? "0" : "1")};
  z-index: 2;
  position: relative;
  display: flex;
  flex-wrap: nowrap;
  flex: 1;
  padding-top: 8%;
  -webkit-overflow-scrolling: touch; /* [3] */
  -ms-overflow-style: -ms-autohiding-scrollbar;
  perspective: 500px;
`;

interface Props {
  posts?: any;
}

const CoverFlow = ({ posts }: Props) => {
  const [activeIndex, setActiveIndex] = useState(0);
  const [midpoint, setMidpoint] = useState<Point>({ x: 0, y: 0 });
  const containerRef = useRef<HTMLDivElement>(null);
  const [selectedAlbum, setSelectedAlbum] = useState<IpodApi.Album>();
  const [playingAlbum, setPlayingAlbum] = useState(false);
  const { hideWindow } = useWindowContext();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, 6000);
  }, []);

  const handleMenuClick = useCallback(() => {
    if (selectedAlbum && playingAlbum) {
      setPlayingAlbum(false);
    } else if (selectedAlbum) {
      setSelectedAlbum(undefined);
    } else {
      hideWindow();
    }
  }, [hideWindow, playingAlbum, selectedAlbum]);

  const forwardScroll = useCallback(() => {
    if (activeIndex < posts.length - 1 && !selectedAlbum && !playingAlbum) {
      setActiveIndex(activeIndex + 1);
    }
  }, [activeIndex, posts.length, playingAlbum, selectedAlbum]);

  const backwardScroll = useCallback(() => {
    if (activeIndex > 0 && !selectedAlbum && !playingAlbum) {
      setActiveIndex(activeIndex - 1);
    }
  }, [activeIndex, playingAlbum, selectedAlbum]);

  const updateMidpoint = useCallback(() => {
    if (containerRef.current) {
      const { width, height } = containerRef.current.getBoundingClientRect();
      setMidpoint({ x: width / 2, y: height / 2 });
    }
  }, []);

  useEffect(updateMidpoint, [updateMidpoint]);

  // useEventListener<IpodEvent>("centerclick", selectAlbum);
  useEventListener<IpodEvent>("menuclick", handleMenuClick);
  useEventListener<IpodEvent>("forwardscroll", forwardScroll);
  useEventListener<IpodEvent>("backwardscroll", backwardScroll);

  return (
    <Container>
      {loading && <LoadingScreen backgroundColor="white" />}

      <AlbumsContainer ref={containerRef} loading={loading}>
        {posts.map((album: any, index: any) => (
          <AlbumCover
            key={`cf-artwork-${album.id}`}
            index={index}
            activeIndex={activeIndex}
            midpoint={midpoint}
            album={album}
            playingAlbum={playingAlbum}
            isSelected={!!selectedAlbum && album.id === selectedAlbum.id}
            setPlayingAlbum={setPlayingAlbum}
            videoId={album.id}
            title={album.caption}
            author={album.ownerUsername}
            thumbnail={album.displayUrl}
          />
        ))}
      </AlbumsContainer>
    </Container>
  );
};

export default CoverFlow;
