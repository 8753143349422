import axios from "axios";
import { useState } from "react";

import { SelectableList } from "components";
import { ViewOptions } from "components/views";
import {
  useEffectOnce,
  useKeyboardInput,
  useMenuHideWindow,
  useScrollHandler,
} from "hooks";
import InstagramFlowView from "../InstagramFlowView";

const InstaSearchView = () => {
  useMenuHideWindow(ViewOptions.insta_search.id);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState([]);
  const [posts, setPosts] = useState([]);

  const handleEnterPress = () => {
    setLoading(true);
    axios
      // .post("http://localhost:3000/api/instagram/postsByUser", {
      .post("/api/postsByUser", {
        user: searchTerm,
      })
      .then((resp) => {
        const list = resp.data.result[0].latestPosts;
        let mediaUrl = {};
        let newOptions = [];

        list.map((post) => {
          switch (post.type) {
            case "Image":
              mediaUrl = { type: "photo", url: post.displayUrl };
              break;
            case "Video":
              mediaUrl = {
                type: "video",
                url: post.videoUrl || "",
              };
              break;
            case "Sidecar":
              mediaUrl = {
                type: "photo",
                url: post.images[0] || "",
              };
              break;
            default:
              break;
          }
          newOptions.push({ ...post, mediaUrl });
        });

        setPosts(newOptions);
        setLoading(false);
      })
      .catch((error) => {
        setOptions([
          {
            type: "Action",
            label: "Search",
            sublabel: searchTerm
              ? `Results for: ${searchTerm}`
              : "Enter text to search",
            imageUrl: "search_icon.svg",
            onSelect: showKeyboard,
          },
          {
            type: "Action",
            label: "No such user / user account is protected.",
            onSelect: () => {},
          },
        ]);
        setLoading(false);
      });
  };

  const { showKeyboard } = useKeyboardInput({
    onChange: (value) => {
      setSearchTerm(value);
      setOptions([
        {
          type: "Action",
          label: "Search",
          sublabel: value ? `Results for: ${value}` : "Enter text to search",
          imageUrl: "search_icon.svg",
          onSelect: showKeyboard,
        },
      ]);
    },
    onEnterPress: handleEnterPress,
  });

  useEffectOnce(() => {
    showKeyboard();

    setOptions([
      {
        type: "Action",
        label: "Search",
        sublabel: searchTerm
          ? `Results for: ${searchTerm}`
          : "Enter text to search",
        imageUrl: "search_icon.svg",
        onSelect: showKeyboard,
      },
    ]);
  });

  const [scrollIndex] = useScrollHandler(ViewOptions.insta_search.id, options);

  return posts.length >= 1 ? (
    <InstagramFlowView posts={posts} />
  ) : (
    <SelectableList
      loading={loading}
      options={options}
      activeIndex={scrollIndex}
      emptyMessage="No results"
    />
  );
};

export default InstaSearchView;
