import { SelectableList } from "components";
import { SearchView, ViewOptions } from "components/views";
import { useMenuHideWindow, useScrollHandler } from "hooks";

const TwitterView = () => {
  useMenuHideWindow(ViewOptions.twitter.id);

  const options = [
    {
      type: "View",
      label: "Search for User",
      viewId: ViewOptions.search.id,
      component: () => <SearchView />,
    },
  ];

  const [scrollIndex] = useScrollHandler(ViewOptions.twitter.id, options);

  return (
    <SelectableList
      loading={false}
      options={options}
      activeIndex={scrollIndex}
    />
  );
};

export default TwitterView;
