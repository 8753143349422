import styled, { css } from "styled-components";
import { Unit } from "utils/constants";

import { SelectableListOption } from ".";

const LabelContainer = styled.div`
  flex: 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-right: ${Unit.MD};
`;

const Label = styled.h3<{ breakLine?: boolean }>`
  margin: 0;
  padding: ${Unit.XXS};
  font-size: 14px;

  white-space: ${(props) => (props.breakLine ? "unset" : "nowrap")};
  overflow: ${(props) => (props.breakLine ? "unset" : "hidden")};
  text-overflow: ${(props) => (props.breakLine ? "unset" : "ellipsis")};
`;

const Sublabel = styled(Label)`
  padding: 0 ${Unit.XXS} ${Unit.XXS};
  margin-top: -4px;
  font-weight: normal;
  font-size: 12px;
  color: rgb(100, 100, 100);
`;

const Container = styled.div<{ isActive?: boolean }>`
  display: flex;
  align-items: center;
  overflow: auto;

  ${(props) =>
    props.isActive &&
    css`
      ${LabelContainer} {
        padding-right: 0;
      }

      ${Label}, ${Sublabel} {
        color: white;
      }
      background: linear-gradient(rgb(60, 184, 255) 0%, rgb(52, 122, 181) 100%);
    `};
`;

const Image = styled.img`
  height: 3rem;
  width: 3rem;
  margin-right: ${Unit.XXS};
`;

const Icon = styled.img`
  margin-left: auto;
`;

interface Props {
  option: SelectableListOption;
  isActive: boolean;
}

const TwitterMediaData = (data: any) => {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      {data.data.type === "photo" && (
        // eslint-disable-next-line jsx-a11y/img-redundant-alt
        <img
          src={data.data.url}
          height={100}
          style={{ marginBottom: "20px" }}
          alt="twitter photo"
        />
      )}
      {data.data.type === "video" && (
        <video
          src={data.data.url}
          height={100}
          autoPlay
          muted
          style={{ marginBottom: "20px" }}
        />
      )}
    </div>
  );
};

const SelectableListItem = ({ option, isActive }: Props) => {
  return (
    <Container isActive={isActive}>
      {option.imageUrl && <Image src={option.imageUrl} />}
      {option.breakLine ? (
        <div style={{ whiteSpace: "normal", width: "100%" }}>
          <Label breakLine>
            <div
              dangerouslySetInnerHTML={{ __html: option.label as string }}
            ></div>
          </Label>
          {option.twitterMediaData.url && (
            <TwitterMediaData data={option.twitterMediaData} />
          )}
        </div>
      ) : (
        <LabelContainer>
          <Label>{option.label}</Label>
          {option.sublabel && <Sublabel>{option.sublabel}</Sublabel>}
        </LabelContainer>
      )}

      {isActive && !option.breakLine && <Icon src="arrow_right.svg" />}
    </Container>
  );
};

export default SelectableListItem;
