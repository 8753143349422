import { useCallback, useEffect, useState } from "react";

import { useEventListener, useSettings, useWindowContext } from "hooks";
import styled from "styled-components";

import CoverFlow from "./CoverFlow";

const Container = styled.div`
  flex: 1;
`;

const CoverFlowView = () => {
  const { hideWindow } = useWindowContext();
  const { isAuthorized } = useSettings();
  const [rawVideos, setRawVideos] = useState([]);

  useEffect(() => {
    fetch(
      "https://www.googleapis.com/youtube/v3/videos?part=snippet,contentDetails,statistics&maxResults=10&chart=mostPopular&key=AIzaSyAYPSjwt1c9q9oEKsvDRKpkux6CXVhduXE"
    )
      .then((response) => response.json())
      .then((data) => {
        setRawVideos(data.items);
      });
  }, []);

  const handleMenuClick = useCallback(() => {}, [hideWindow, isAuthorized]);

  useEventListener("menuclick", handleMenuClick);

  return (
    <Container>
      <CoverFlow rawVideos={rawVideos} />
    </Container>
  );
};

export default CoverFlowView;
