import GamesPreview from "./GamesPreview";
import HNPreview from "./HNPreview";
import InstagramPreview from "./InstagramPreview";
import NowPlayingPreview from "./NowPlayingPreview";
import ServicePreview from "./ServicePreview";
import SettingsPreview from "./SettingsPreview";
import ThemePreview from "./ThemePreview";
import TwitterPreview from "./TwitterPreview";
import YoutubePreview from "./YoutubePreview";

export enum PREVIEW {
  MUSIC = "music",
  GAMES = "games",
  SETTINGS = "settings",
  NOW_PLAYING = "nowPlaying",
  SERVICE = "service",
  THEME = "theme",
  HACKER_NEWS = "hackernews",
  YOUTUBE = "youtube",
  TWITTER = "twitter",
  INSTAGRAM = "instagram",
}

export const Previews = {
  [PREVIEW.MUSIC]: () => <HNPreview />,
  [PREVIEW.GAMES]: () => <GamesPreview />,
  [PREVIEW.SETTINGS]: () => <SettingsPreview />,
  [PREVIEW.NOW_PLAYING]: () => <NowPlayingPreview />,
  [PREVIEW.SERVICE]: () => <ServicePreview />,
  [PREVIEW.THEME]: () => <ThemePreview />,
  [PREVIEW.HACKER_NEWS]: () => <HNPreview />,
  [PREVIEW.YOUTUBE]: () => <YoutubePreview />,
  [PREVIEW.TWITTER]: () => <TwitterPreview />,
  [PREVIEW.INSTAGRAM]: () => <InstagramPreview />,
};
