import axios from "axios";
import { ViewOptions } from "components";
import { useEventListener, useWindowContext } from "hooks";
import { useEffect, useState } from "react";
import styled, { css } from "styled-components";
import { IpodEvent } from "utils/events";
import { Point } from "./CoverFlow";

const getOffsetPx = (offset: number, midpoint: number) => {
  if (offset === 0) return 0;
  const val = midpoint - 46 + offset * 58;

  return val + (offset < 0 ? -58 : 24);
};

interface ContainerProps {
  isActive: boolean;
  isSelected: boolean;
  isPlaying: boolean;
  isHidden: boolean;
  midpoint: Point;
  offset: number;
  index: number;
  activeIndex: number;
}

const Container = styled.div.attrs((props: ContainerProps) => ({
  style: {
    background: "transparent",
    backgroundSize: "cover",
  },
}))<ContainerProps>`
  z-index: ${(props) => 1 - Math.abs(props.index - props.activeIndex)};
  position: absolute;
  height: 8em;
  width: 8em;
  transition: transform 0.25s, opacity 0.35s, background 0.35s;
  transform-style: preserve-3d;
  -webkit-box-reflect: below 0px -webkit-gradient(linear, left top, left bottom, from(transparent), color-stop(70%, transparent), to(rgba(240, 240, 240, 0.2)));
  opacity: ${(props) => props.isHidden && 0};

  ${(props) =>
    props.isActive &&
    css`
      transition: transform 0.3s, opacity 0.35s, background 0.35s;
      transform: translate3d(${props.midpoint.x - 60}px, 4px, 20px)
        ${props.isSelected && "translateX(-20.5%) translateY(1.5%) scale(2.05)"};

      ${props.isSelected &&
      css`
        transition: transform 0.5s, opacity 0.35s, background 0.35s;
        -webkit-box-reflect: none;

        ${Artwork} {
          opacity: 0;
        }
      `};

      ${props.isPlaying &&
      css`
        transform: translate(${props.midpoint.x / 6}px, -10px) rotateY(18deg);

        ${Artwork} {
          opacity: 1;
        }
      `};
    `};

  ${(props) =>
    !props.isActive &&
    css`
      transform: translateX(${props.offset}px) scale(1.1) translateZ(-65px)
        rotateY(${props.index < props.activeIndex ? "70deg" : "-70deg"});
    `};
`;

const Artwork = styled.img`
  z-index: 0;
  position: absolute;
  height: 130px;
  width: 150px;
  transition: opacity 0.5s;
`;

interface Props {
  index: number;
  activeIndex: number;
  midpoint: Point;
  album: any;
  isSelected: boolean;
  playingAlbum: boolean;
  setPlayingAlbum: (val: boolean) => void;
  videoId?: string;
  title?: string;
  author?: string;
  thumbnail?: string;
}

const AlbumCover = ({
  album,
  midpoint,
  index,
  activeIndex,
  isSelected,
  playingAlbum,
  setPlayingAlbum,
  videoId,
  title,
  author,
  thumbnail,
}: Props) => {
  const isVisible = index > activeIndex - 15 && index < activeIndex + 15;
  const isActive = index === activeIndex;
  const offset = getOffsetPx(index - activeIndex, midpoint.x);
  const isHidden = !isActive && playingAlbum;
  const [image, setImage] = useState("");
  const [video, setVideo] = useState("");
  const [date, setDate] = useState(new Date());
  const { hideWindow } = useWindowContext();

  const handleCenterClick = () => {
    if (isActive) {
      window.open(album.url);
    }

    hideWindow(ViewOptions.keyboard.id);
  };

  useEventListener<IpodEvent>("centerclick", handleCenterClick);

  useEffect(() => {
    axios
      // .post("http://localhost:3000/api/instagram/contentToBase64", {
      .post("/api/media", {
        url: album.mediaUrl.url,
      })
      .then((resp: any) => {
        switch (album.type) {
          case "Image":
            setImage(resp.data.base64);
            break;
          case "Video":
            setVideo(resp.data.base64);
            break;
          case "Sidecar":
            setImage(resp.data.base64);
            break;
          default:
            break;
        }
      })
      .catch((error: any) => {
        console.log(error);
      });

    setDate(new Date(album.timestamp));
  }, []);

  useEffect(() => {
    if (album.type === "Video") {
      const videoEl = document.getElementById(
        `video_${album.id}`
      ) as HTMLVideoElement;
      if (isActive && videoEl) {
        videoEl?.play();
      } else {
        if (videoEl) {
          videoEl?.pause();
        }
      }
    }
  }, [isActive]);

  return isVisible ? (
    <Container
      isActive={isActive}
      isSelected={isSelected}
      isHidden={isHidden}
      midpoint={midpoint}
      offset={offset}
      index={index}
      activeIndex={activeIndex}
      isPlaying={isSelected && playingAlbum}
    >
      {image && <Artwork src={`data:image/png;base64,${image}`} />}
      {video && (
        <video
          src={`data:video/webm;base64,${video}`}
          style={{ height: "150px" }}
          id={`video_${album.id}`}
        />
      )}
      <div id={`player_${videoId}`}></div>
      <div
        style={{
          transition: ".3s",
          opacity: isActive && !isSelected ? 0.8 : 0,
          fontSize: "10px",
          position: "fixed",
          left: "-40px",
          right: "-40px",
          bottom: "-50px",
          marginTop: "10px",
          width: "200px",
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
        }}
      >
        <b>@{author}</b> {title} <br />
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <div>
            {album.likesCount} likes - {album.commentsCount} comments
          </div>
          <div>
            {date.getMonth()}/{date.getDate()}/{date.getFullYear()}
          </div>
        </div>
      </div>
    </Container>
  ) : null;
};

export default AlbumCover;
