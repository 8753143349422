import { SelectableList, SelectableListOption } from "components";
import { ViewOptions } from "components/views";
import { useMenuHideWindow, useScrollHandler } from "hooks";
import styled from "styled-components";
import { Unit } from "utils/constants";

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const Image = styled.img`
  height: ${Unit.XL};
  width: auto;
  margin: ${Unit.XS};
`;

const TitleContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: ${Unit.MD} ${Unit.MD} 0;
`;

const Title = styled.h3`
  margin: 0;
  font-size: 16px;
  font-weight: 900;
`;

const Description = styled.h3`
  margin: 0 0 ${Unit.MD};
  font-size: 14px;
  font-weight: normal;
  text-align: center;
  margin-top: 10px;
`;

const ListContainer = styled.div`
  flex: 1;
`;

const AboutSocialView = () => {
  useMenuHideWindow(ViewOptions.about.id);
  const options: SelectableListOption[] = [
    {
      type: "Link",
      label: "GitHub Repo",
      url: "https://github.com/agencyenterprise/ipod-social",
    },
    {
      type: "Link",
      label: "Our Website",
      url: "https://samedayskunkworks.com",
    },
    {
      type: "Link",
      label: "Twitter @DailySkunkwork",
      url: "https://twitter.com/DailySkunkwork",
    },
  ];

  const [scrollIndex] = useScrollHandler(ViewOptions.about.id, options);

  return (
    <Container>
      <ListContainer>
        <TitleContainer>
          <Title>iPod Social</Title>
        </TitleContainer>
        <Description>
          Made with{" "}
          <span aria-label="heart" role="img">
            ❤️
          </span>{" "}
          by Same Day Skunkworks
        </Description>
        <SelectableList options={options} activeIndex={scrollIndex} />
      </ListContainer>
    </Container>
  );
};

export default AboutSocialView;
