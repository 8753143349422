import axios from "axios";
import { useState } from "react";

import { SelectableList } from "components";
import { ViewOptions } from "components/views";
import {
  useEffectOnce,
  useKeyboardInput,
  useMenuHideWindow,
  useScrollHandler,
} from "hooks";

const InstaSearchView = () => {
  useMenuHideWindow(ViewOptions.insta_search.id);
  const [searchTerm, setSearchTerm] = useState("");
  const [loading, setLoading] = useState(false);
  const [options, setOptions] = useState<any>([]);

  const handleEnterPress = () => {
    setLoading(true);
    axios
      // .post("http://localhost:3000/api/twitter/tweetsByUser", {
      .post("/api/tweetsByUser", {
        //TODO
        // user: "MySportsUpdate", //TODO: remove this
        user: searchTerm,
      })
      .then((resp) => {
        const twitterUser = resp.data.data.user;
        const twitterMedias = resp.data.data.tweets.includes?.media;
        let newOptions: any = [];

        resp.data.data.tweets.data.map((tweet: any) => {
          let mediaUrl = {};
          if (tweet.attachments?.media_keys.length > 0) {
            twitterMedias.map((media: any) => {
              if (media.media_key === tweet.attachments.media_keys[0]) {
                switch (media.type) {
                  case "photo":
                    mediaUrl = { type: media.type, url: media.url };
                    break;
                  case "video":
                    mediaUrl = {
                      type: media.type,
                      url: media.variants[0].url || "",
                    };
                    break;
                }
              }
            });
          }

          newOptions.push({
            type: "Link",
            label: `${tweet.text}`,
            url: `https://twitter.com/${twitterUser.username}/status/${tweet.id}`,
            breakLine: true,
            twitterMediaData: mediaUrl,
          });
        });

        setOptions(newOptions);
        setLoading(false);
      })
      .catch((error) => {
        setOptions([
          {
            type: "Action",
            label: "Search",
            sublabel: searchTerm
              ? `Results for: ${searchTerm}`
              : "Enter text to search",
            imageUrl: "search_icon.svg",
            onSelect: showKeyboard,
          },
          {
            type: "Action",
            label: error.response.data.message,
            onSelect: () => {},
          },
        ]);
        setLoading(false);
      });
  };

  const { showKeyboard } = useKeyboardInput({
    onChange: (value) => {
      setSearchTerm(value);
      setOptions([
        {
          type: "Action",
          label: "Search",
          sublabel: value ? `Results for: ${value}` : "Enter text to search",
          imageUrl: "search_icon.svg",
          onSelect: showKeyboard,
        },
      ]);
    },
    onEnterPress: handleEnterPress,
  });

  useEffectOnce(() => {
    showKeyboard();

    setOptions([
      {
        type: "Action",
        label: "Search",
        sublabel: searchTerm
          ? `Results for: ${searchTerm}`
          : "Enter text to search",
        imageUrl: "search_icon.svg",
        onSelect: showKeyboard,
      },
    ]);
  });

  const [scrollIndex] = useScrollHandler(ViewOptions.insta_search.id, options);

  return (
    <SelectableList
      loading={loading}
      options={options}
      activeIndex={scrollIndex}
      emptyMessage="No results"
    />
  );
};

export default InstaSearchView;
