import { useCallback, useMemo } from "react";

import { SelectableList, SelectableListOption } from "components";
import { PREVIEW } from "components/previews";
import {
  CoverFlowView,
  GamesView,
  HackerNewsView,
  InstagramView,
  NowPlayingView,
  SettingsView,
  TwitterView,
  ViewOptions,
  WINDOW_TYPE,
} from "components/views";
import {
  useAudioPlayer,
  useEventListener,
  useMusicKit,
  useScrollHandler,
  useSettings,
  useSpotifySDK,
  useWindowContext,
} from "hooks";
import { IpodEvent } from "utils/events";

const strings = {
  nowPlaying: "Now Playing",
};

const HomeView = () => {
  const { isAuthorized } = useSettings();
  const { signIn: signInWithApple, isConfigured: isMkConfigured } =
    useMusicKit();
  const { nowPlayingItem } = useAudioPlayer();
  const { signIn: signInWithSpotify } = useSpotifySDK();
  const { showWindow, windowStack } = useWindowContext();

  const options: SelectableListOption[] = useMemo(
    () => [
      {
        type: "View",
        label: "YouTube",
        viewId: ViewOptions.coverFlow.id,
        component: () => <CoverFlowView />,
        preview: PREVIEW.YOUTUBE,
      },
      {
        type: "View",
        label: "Twitter",
        viewId: ViewOptions.twitter.id,
        component: () => <TwitterView />,
        preview: PREVIEW.TWITTER,
      },
      {
        type: "View",
        label: "Instagram",
        viewId: ViewOptions.instagram.id,
        component: () => <InstagramView />,
        preview: PREVIEW.INSTAGRAM,
      },
      {
        type: "View",
        label: "Hacker News",
        viewId: ViewOptions.hackernews.id,
        component: () => <HackerNewsView />,
        preview: PREVIEW.HACKER_NEWS,
      },
      {
        type: "View",
        label: "Games",
        viewId: ViewOptions.games.id,
        component: () => <GamesView />,
        preview: PREVIEW.GAMES,
      },
      {
        type: "View",
        label: "Settings",
        viewId: ViewOptions.settings.id,
        component: () => <SettingsView />,
        preview: PREVIEW.SETTINGS,
      },
    ],
    [
      isAuthorized,
      isMkConfigured,
      nowPlayingItem,
      signInWithApple,
      signInWithSpotify,
    ]
  );

  const [scrollIndex] = useScrollHandler(ViewOptions.home.id, options);

  const handleIdleState = useCallback(() => {
    const activeView = windowStack[windowStack.length - 1];

    const shouldShowNowPlaying =
      !!nowPlayingItem &&
      activeView.id !== ViewOptions.nowPlaying.id &&
      activeView.id !== ViewOptions.coverFlow.id &&
      activeView.id !== ViewOptions.keyboard.id;

    // Only show the now playing view if we're playing a song and not already on that view.
    if (shouldShowNowPlaying) {
      showWindow({
        id: ViewOptions.nowPlaying.id,
        type: WINDOW_TYPE.FULL,
        component: NowPlayingView,
      });
    }
  }, [nowPlayingItem, showWindow, windowStack]);

  useEventListener<IpodEvent>("idle", handleIdleState);

  return <SelectableList options={options} activeIndex={scrollIndex} />;
};

export default HomeView;
