export { default as AboutSocialView } from "./AboutSocialView";
export { default as AboutView } from "./AboutView";
export { default as AlbumsView } from "./AlbumsView";
export { default as AlbumView } from "./AlbumView";
export { default as ArtistsView } from "./ArtistsView";
export { default as ArtistView } from "./ArtistView";
export { default as BrickGameView } from "./BrickGameView";
export { default as CoverFlowView } from "./CoverFlowView";
export { default as GamesView } from "./GamesView";
export { default as HackerNewsNewView } from "./HackerNewsNewView";
export { default as HackerNewsView } from "./HackerNewsView";
export { default as HomeView } from "./HomeView";
export { default as InstagramView } from "./InstagramView";
export { default as InstaSearchView } from "./InstaSearchView";
export { default as MusicView } from "./MusicView";
export { default as NowPlayingView } from "./NowPlayingView";
export { default as PlaylistsView } from "./PlaylistsView";
export { default as PlaylistView } from "./PlaylistView";
export { default as SearchView } from "./SearchView";
export { default as SettingsView } from "./SettingsView";
export { default as SongsView } from "./SongsView";
export { default as TwitterView } from "./TwitterView";

export enum WINDOW_TYPE {
  SPLIT = "SPLIT",
  FULL = "FULL",
  COVER_FLOW = "COVER_FLOW",
  ACTION_SHEET = "ACTION_SHEET",
  POPUP = "POPUP",
  KEYBOARD = "KEYBOARD",
}

type ViewOption = {
  id: string;
  title: string;
  type: WINDOW_TYPE;
};

export const ViewOptions: Record<string, ViewOption> = {
  //USED VIEWS
  home: { id: "home", title: "iPod Social", type: WINDOW_TYPE.SPLIT },
  music: { id: "music", title: "Hacker News", type: WINDOW_TYPE.FULL },
  hackernews: {
    id: "hackernews",
    title: "Hacker News",
    type: WINDOW_TYPE.FULL,
  },
  hackernews_index: {
    id: "hackernews_index",
    title: "Hacker News",
    type: WINDOW_TYPE.FULL,
  },
  hackernews_post: {
    id: "hackernews_post",
    title: "Hacker News",
    type: WINDOW_TYPE.FULL,
  },
  hackernews_comments: {
    id: "hackernews_comments",
    title: "Hacker News",
    type: WINDOW_TYPE.FULL,
  },
  hn_new: { id: "hn_new", title: "New", type: WINDOW_TYPE.FULL },
  hn_past: { id: "hn_past", title: "Past", type: WINDOW_TYPE.FULL },
  hn_comments: { id: "hn_comments", title: "Comments", type: WINDOW_TYPE.FULL },
  hn_ask: { id: "hn_ask", title: "Ask", type: WINDOW_TYPE.FULL },
  hn_show: { id: "hn_show", title: "Show", type: WINDOW_TYPE.FULL },
  hn_jobs: { id: "hn_jobs", title: "Jobs", type: WINDOW_TYPE.FULL },
  hn_submit: { id: "hn_submit", title: "Submit", type: WINDOW_TYPE.FULL },
  twitter: { id: "twitter", title: "Twitter", type: WINDOW_TYPE.FULL },
  instagram: { id: "instagram", title: "Instagram", type: WINDOW_TYPE.FULL },
  insta_search: {
    id: "insta_search",
    title: "Search Instagram",
    type: WINDOW_TYPE.FULL,
  },

  // Split Screen Views

  games: { id: "games", title: "Games", type: WINDOW_TYPE.FULL },
  settings: { id: "settings", title: "Settings", type: WINDOW_TYPE.FULL },

  // Fullscreen Views
  about: { id: "about", title: "About", type: WINDOW_TYPE.FULL },
  about_social: {
    id: "aboutSocial",
    title: "About Social",
    type: WINDOW_TYPE.FULL,
  },
  artists: { id: "artists", title: "Artists", type: WINDOW_TYPE.FULL },
  artist: { id: "artist", title: "Artist", type: WINDOW_TYPE.FULL },
  albums: { id: "albums", title: "Albums", type: WINDOW_TYPE.FULL },
  album: { id: "album", title: "Album", type: WINDOW_TYPE.FULL },
  songs: { id: "songs", title: "Songs", type: WINDOW_TYPE.FULL },
  nowPlaying: {
    id: "nowPlaying",
    title: "Now Playing",
    type: WINDOW_TYPE.FULL,
  },
  playlists: { id: "playlists", title: "Playlists", type: WINDOW_TYPE.FULL },
  playlist: { id: "playlist", title: "Playlist", type: WINDOW_TYPE.FULL },
  search: { id: "search", title: "Search Twitter", type: WINDOW_TYPE.FULL },
  brickGame: { id: "brickGame", title: "Brick", type: WINDOW_TYPE.FULL },
  tweets: { id: "tweets", title: "Tweet", type: WINDOW_TYPE.FULL },

  // CoverFlow view
  coverFlow: {
    id: "coverFlow",
    title: "YouTube - Most Popular Videos",
    type: WINDOW_TYPE.COVER_FLOW,
  },

  // Action sheets
  mediaActionSheet: {
    id: "mediaActionSheet",
    title: "Media Options",
    type: WINDOW_TYPE.ACTION_SHEET,
  },
  serviceTypeActionSheet: {
    id: "serviceTypeActionSheet",
    title: "Service",
    type: WINDOW_TYPE.ACTION_SHEET,
  },
  deviceThemeActionSheet: {
    id: "deviceThemeActionSheet",
    title: "Device theme",
    type: WINDOW_TYPE.ACTION_SHEET,
  },
  signinPopup: {
    id: "signinPopup",
    title: "Sign in",
    type: WINDOW_TYPE.ACTION_SHEET,
  },
  signOutPopup: {
    id: "signOutPopup",
    title: "Sign out",
    type: WINDOW_TYPE.ACTION_SHEET,
  },

  // Popups
  spotifyNotSupportedPopup: {
    id: "spotifyNotSupportedPopup",
    title: "Unsupported browser",
    type: WINDOW_TYPE.POPUP,
  },
  spotifyNonPremiumPopup: {
    id: "spotifyNonPremiumPopup",
    title: "Premium",
    type: WINDOW_TYPE.POPUP,
  },

  // Keyboard
  keyboard: {
    id: "keyboard",
    title: "Keyboard",
    type: WINDOW_TYPE.KEYBOARD,
  },
};

export default ViewOptions;
