import { useEffect, useState } from "react";

import axios from "axios";
import { SelectableList } from "components";
import { ViewOptions } from "components/views";
import { useMenuHideWindow, useScrollHandler } from "hooks";
import HackerNewsPostView from "../HackerNewsPostView";

const HackerNewsView = () => {
  const [list, setList] = useState([]);
  const [loading, setLoading] = useState(true);
  const [options, setOptions] = useState([]);
  useMenuHideWindow(ViewOptions.hackernews.id);

  useEffect(() => {
    setLoading(true);
    axios
      .get("https://hacker-news.firebaseio.com/v0/topstories.json")
      .then((resp) => {
        resp.data.length >= 11
          ? setList(resp.data.slice(0, 10))
          : setList(resp.data);
      });
  }, []);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(async () => {
    if (list.length >= 1) {
      const test = [];
      for (var i = 0; i < list.length; i++) {
        await axios
          .get(
            "https://hacker-news.firebaseio.com/v0/item/" + list[i] + ".json"
          )
          // eslint-disable-next-line no-loop-func
          .then((resp) => {
            test.push({
              type: "View",
              label: `${i + 1}. ${resp.data.title}`,
              viewId: ViewOptions.hackernews_index.id,
              component: () => <HackerNewsPostView post={resp.data} />,
            });
          });
      }
      setLoading(false);
      setOptions(test);
    }
  }, [list]);

  const [scrollIndex] = useScrollHandler(ViewOptions.hackernews.id, options);

  return (
    <SelectableList
      loading={loading}
      options={options}
      activeIndex={scrollIndex}
    />
  );
};

export default HackerNewsView;
