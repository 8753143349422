import { useCallback } from "react";

import { useEventListener, useWindowContext } from "hooks";
import styled from "styled-components";

import CoverFlow from "./CoverFlow";

const Container = styled.div`
  flex: 1;
`;

const InstagramFlowView = ({ posts }) => {
  const { hideWindow } = useWindowContext();

  const handleMenuClick = useCallback(() => {}, [hideWindow]);

  useEventListener("menuclick", handleMenuClick);

  return (
    <Container>
      <CoverFlow posts={posts} />
    </Container>
  );
};

export default InstagramFlowView;
